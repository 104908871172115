import UIkit from 'uikit';

// import styles
import './main.scss';

// import greensock lib
import gsap from "./gsap/src/gsap-core";
import CSSPlugin from "./gsap/src/CSSPlugin";
import CSSRulePlugin from "./gsap/src/CSSRulePlugin";
import MotionPathPlugin from './gsap/src/MotionPathPlugin';
import TextPlugin from './gsap/src/TextPlugin';
import ScrollToPlugin from './gsap/src/ScrollToPlugin';
import ScrollTrigger from './gsap/src/ScrollTrigger';
import DrawSVGPlugin from './gsap/src/DrawSVGPlugin';
import ScrambleTextPlugin from './gsap/src/ScrambleTextPlugin';

gsap.registerPlugin(
    CSSPlugin,
    CSSRulePlugin,
    MotionPathPlugin,
    TextPlugin,
    ScrollToPlugin,
    ScrollTrigger,
    DrawSVGPlugin,
    ScrambleTextPlugin
);

// font awesome: https://fontawesome.com/docs/apis/javascript/tree-shaking
// treeshaking does not work => deep imports
// import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons/faShoppingCart';
// import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
// import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
// import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
// import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
// import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
// import { faLongArrowDown } from '@fortawesome/pro-regular-svg-icons/faLongArrowDown';
// import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
// import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
// import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
// import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
// import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
// import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
// import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
// import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
// import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
// import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
// import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
// import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
// import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
// import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
// import { faArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons/faArrowRightFromBracket';
//
// import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
// import { faGooglePlus } from '@fortawesome/free-brands-svg-icons/faGooglePlus';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
// import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';
//
//
// library.add(
//     faShoppingCart,
//     faInfoCircle,
//     faPlus,
//     faCheck,
//     faTimes,
//     faLongArrowRight,
//     faLongArrowDown,
//     faAngleLeft,
//     faAngleDown,
//     faAngleRight,
//     faChevronRight,
//     faChevronDown,
//     faChevronUp,
//     faSearch,
//     faArrowRight,
//     faArrowLeft,
//     faEnvelope,
//     faPhone,
//     faTrashAlt,
//     faExclamationCircle,
//     faCog,
//     faArrowRightFromBracket,
//
//     faFacebookSquare,
//     faGooglePlus,
//     faLinkedin,
//     faSquareXTwitter,
// );
//
// dom.watch();

// initialize HTML and hyperscript

const htmx = require('htmx.org');
window.htmx = htmx;
import * as hyperscript from 'hyperscript.org';
hyperscript.browserInit();

export {htmx, UIkit, gsap};